import { FormControl, FormGroup } from '@angular/forms';
import {
  HTTP_AP_ListMortgageProducts_ForTable_Request_Filter,
  HTTP_AP_ListMortgageProducts_ForTable_Request_Sort,
  HTTP_AP_ListMortgageProducts_ForTable_Response_MortgageProduct,
} from '@api-new/mortgageservice';
import { FormRawValue } from '@shared-lib/models/form-raw-value.model';
import { ProtoFormModel } from '@shared-lib/models/proto-form.model';
import { RowDefinitionType, TableRowDefinitionModel } from '@shared/components/table';

export const mortgageProductTableRowDefinition: TableRowDefinitionModel<
  HTTP_AP_ListMortgageProducts_ForTable_Response_MortgageProduct,
  HTTP_AP_ListMortgageProducts_ForTable_Request_Sort
>[] = [
  {
    columnLabel: 'Name',
    columnKey: 'productName',
    type: RowDefinitionType.TEXT,
    width: {
      min: '16rem',
      max: '16rem',
    },
  },
  {
    columnLabel: 'Lender',
    columnKey: 'lenderLogo',
    type: RowDefinitionType.IMAGE,
    width: {
      min: '7.5rem',
      max: '7.5rem',
    },
  },
  {
    columnLabel: 'Product Code',
    columnKey: 'productCode',
    type: RowDefinitionType.TEXT,
    widthPercentage: 10,
    sortKey: 'byProductCode',
  },
  {
    columnLabel: 'Mortgage Type',
    columnKey: 'mortgageType',
    type: RowDefinitionType.MORTGAGE_TYPE,
    widthPercentage: 10,
  },
  {
    columnLabel: 'Initial Rate',
    type: RowDefinitionType.PERCENT,
    columnKey: 'initialMortgageProductStepInterestRate',
    widthPercentage: 10,
    sortKey: 'byInitialMortgageProductStepInterestRate',
  },
  {
    columnLabel: 'Rate Type',
    columnKey: 'initialMortgageProductStepInterestRateType',
    widthPercentage: 10,
  },
  {
    columnLabel: 'Initial Period',
    type: RowDefinitionType.DURATION_MONTHS,
    columnKey: 'initialMortgageProductStepEndTermMonths',
    widthPercentage: 10,
    sortKey: 'byInitialMortgageProductStepEndTermMonths',
  },
  {
    columnLabel: 'Max LTV',
    type: RowDefinitionType.PERCENT,
    columnKey: 'ltvMaximumLtv',
    widthPercentage: 10,
    sortKey: 'byLtvMaximumLtv',
  },
];

export const mortgageProductTableFilterDefaults: MortgageProductTableFilterFormValues = {
  lenderIds: [],
  mortgageType: null,
  initialMortgageProductStepInterestRate: { min: null, max: null },
  initialMortgageProductStepInterestRateType: null,
  initialMortgageProductStepEndTermMonths: { min: null, max: null },
  ltvMaximumLtv: { min: null, max: null },
  availableDate: null,
  // NOT implemented filters
  applicantIsAvailableForExistingBorrowerExclusive: null,
  applicantIsAvailableForFirstTimeBuyer: null,
  applicantIsAvailableForNewBorrowerRemortgage: null,
  incentivesFreePropertyValuation: null,
  loanAmountMaximumLoanAmount: null,
  productSupportsGreenMortgageExclusive: null,
  productSupportsOwnershipShareLoan: null,
};

export type MortgageProductTableFilterFormModel = ProtoFormModel<HTTP_AP_ListMortgageProducts_ForTable_Request_Filter, 'availableDate'> & {
  availableDate: FormControl<Date | null>;
};

export type MortgageProductTableFilterFormGroup = FormGroup<MortgageProductTableFilterFormModel>;

export type MortgageProductTableFilterFormValues = FormRawValue<MortgageProductTableFilterFormGroup>;

export const buildMortgageProductTableFilterForm = (): MortgageProductTableFilterFormGroup =>
  new FormGroup<MortgageProductTableFilterFormModel>({
    lenderIds: new FormControl([]),
    mortgageType: new FormControl(null),
    initialMortgageProductStepInterestRate: new FormControl({ min: null, max: null }),
    initialMortgageProductStepInterestRateType: new FormControl(null),
    initialMortgageProductStepEndTermMonths: new FormControl({ min: null, max: null }),
    ltvMaximumLtv: new FormControl({ min: null, max: null }),
    availableDate: new FormControl(null),
    // NOT implemented filters
    applicantIsAvailableForExistingBorrowerExclusive: new FormControl(null),
    applicantIsAvailableForFirstTimeBuyer: new FormControl(null),
    applicantIsAvailableForNewBorrowerRemortgage: new FormControl(null),
    incentivesFreePropertyValuation: new FormControl(null),
    loanAmountMaximumLoanAmount: new FormControl(null),
    productSupportsGreenMortgageExclusive: new FormControl(null),
    productSupportsOwnershipShareLoan: new FormControl(null),
  });
